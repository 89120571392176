<template>
  <div class="right-list">
    <div class="content-box">
      <div class="list-title">
        <span>{{ name }} 运输记录</span>
        <span>共 {{ page.total }} 条</span>
      </div>
      <div class="list-box">
        <div class="search-type">时间查询:</div>
        <div class="time-search">
          <span
            ><DatePicker
              v-model="searchDate.start"
              placeholder="请选择开始时间"
            ></DatePicker
          ></span>
          <span style="padding: 4px 0">|</span>
          <span
            ><DatePicker
              v-model="searchDate.end"
              placeholder="请选择结束时间"
            ></DatePicker
          ></span>
        </div>
        <div class="search-type">订单查询:</div>
        <div class="order-list-box">
          <scroll-box>
            <div
              @click="checkOrder(order)"
              v-for="order in transportList"
              :key="order.id"
              :class="[
                'list-item',
                currentOrder && currentOrder.id === order.id
                  ? 'current-order'
                  : '',
              ]"
            >
              <div class="item-time">
                <span>{{ order.startTime }}</span>
                <span>{{
                  order.transportStatus == 3 ? "已完成" : "进行中"
                }}</span>
              </div>
              <div class="destination">
                <span>{{ order.originAddress }}</span>
                <span class="iconfont fromto">&#xe6cd;</span>
                <span>
                  {{ order.transportAddress }}
                </span>
              </div>
              <div class="driver-info">
                <span class="driver-name">
                  {{ order.driverName }}
                </span>
                <span class="driver-phone">
                  {{ order.driverPhone }}
                </span>
              </div>
            </div>
          </scroll-box>
        </div>
      </div>
      <div class="page-box">
        <Page
          @on-change="pageChange"
          size="small"
          :current="page.pageNo"
          :page-size="page.pageSize"
          :total="page.total"
        ></Page>
      </div>
    </div>
  </div>
</template>
<script>
import scrollBox from "@/components/scrollBox";
import moment from "moment";

export default {
  props: {
    name: String,
    searchOption: Object,
  },
  components: {
    scrollBox,
  },
  data() {
    return {
      page: {
        pageNo: 1,
        pageSize: 5,
        total: 0,
      },
      //   运输列表
      transportList: [],
      // 当前查看的订单
      currentOrder: null,
      searchDate: {
        start: null,
        end: null,
      },
    };
  },
  watch: {
    searchDate: {
      handler() {
        this.searchByTime();
      },
      deep: true,
    },
    name: {
      handler() {
        this.searchByTime();
      },
      deep: true
    },
  },
  methods: {
    //   查询运输列表
    getTransportList() {
      this.$post(this.$api.TRANSPORT.LIST, {
        ...this.searchOption,
        transportStatus: "2,3",
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
      }).then((res) => {
        this.transportList = res.list;
        this.page.total = +res.total;
      });
    },
    // 分页改变
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getTransportList();
    },
    // 查看订单详情
    checkOrder(order) {
      this.currentOrder = order;
      this.$emit("order-check", { ...order });
    },
    searchByTime() {
      if (!this.searchDate.start || !this.searchDate.end) return;
      if (moment(this.searchDate.start).isAfter(this.searchDate.end)) {
        this.$Message.warning("开始时间不能晚于结束时间");
        return;
      }
      this.currentOrder = null;
      this.$emit("order-check", {
        carName: this.name,
        startTime: moment(this.searchDate.start).format("YYYY-MM-DD"),
        endTime: moment(this.searchDate.end).format("YYYY-MM-DD"),
      });
    },
  },
  beforeDestroy() {
    this.$emit("order-check");
  },
  mounted() {
    this.getTransportList();
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>